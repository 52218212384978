@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.required {
  color: red;
}
.order-content {
  padding: 0 20px 90px;
  h4 {
    font-family: 'PingFang Bold';
    font-size: 14px;
    margin: 20px 0 10px 0;
  }
  .el-input,
  .el-select {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
td .link {
  cursor: pointer;
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}
.custom-table tbody td {
  padding: 18px 10px;
}
.print-image {
  display: flex;
  // flex-direction: column;
  align-items: center;
}
.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}
.el-tag-other {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 2;
  background-color: #fff7e8;
  border-color: #ffefd1;
  color: #ffae18;
}
.selectFlex {
  display: flex;
  align-items: center;
}
