@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }

  .table-wrapper {
    background-color: #f5f7fa;
    padding: 15px;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }

  tr.el-table__row.hidden {
    display: none;
  }
}

tr.el-table__row.hidden {
  display: none;
}

.inner-class {
  /* height: 230px !important;
  padding: 15px 15px 0 15px; */
}
